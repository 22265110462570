import React, { useState } from 'react'
import { Layout, Form, Input, Button, Select } from 'antd'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import {
  registerAttendeeAction,
  deleteAttendeeAction,
} from '../../store/attendees/Attendees.actions.js'

const { Content } = Layout
const { Option } = Select

const GuestRegistry = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleGuestAction = async (data, actionType) => {
    try {
      setIsLoading(true)
      switch (actionType) {
        case 'create':
          await dispatch(registerAttendeeAction(data))
          break
        case 'delete':
          const { first_name, middle_name, last_name } = data
          await dispatch(
            deleteAttendeeAction({ first_name, middle_name, last_name })
          )
          break
        default:
          break
      }
      setIsLoading(false)
      navigate('/home')
    } catch (err) {
      setIsLoading(false)
      // Fehlerbehandlung (optional: hier könnte eine Fehlermeldung angezeigt werden)
    }
  }

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Vorname ist ein verpflichtendes Feld'),
    last_name: Yup.string().required('Nachname ist ein verpflichtendes Feld'),
    username: Yup.string().required(
      'Benutzername ist ein verpflichtendes Feld'
    ),
    password: Yup.string().required('Passwort ist ein verpflichtendes Feld'),
    attendee_category: Yup.string().required(
      'Kategorie ist ein verpflichtendes Feld'
    ),
  })

  return (
    <Content style={styles.content}>
      <div style={styles.container}>
        <h2 style={styles.title}>Gast Registrieren</h2>
        <Formik
          initialValues={{
            first_name: '',
            middle_name: '',
            last_name: '',
            username: '',
            password: '',
            attendee_category: 'gast', // Standardmäßig Gast
          }}
          validationSchema={validationSchema}
          validateOnBlur
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            setSubmitting(false)
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form style={styles.form}>
              <Form.Item
                validateStatus={
                  errors.first_name && touched.first_name ? 'error' : ''
                }
                help={
                  errors.first_name && touched.first_name ? errors.first_name : ''
                }
              >
                <Input
                  name="first_name"
                  placeholder="Vorname"
                  onChange={handleChange}
                  value={values.first_name}
                />
              </Form.Item>
              <Form.Item
                validateStatus={
                  errors.middle_name && touched.middle_name ? 'error' : ''
                }
                help={
                  errors.middle_name && touched.middle_name
                    ? errors.middle_name
                    : ''
                }
              >
                <Input
                  name="middle_name"
                  placeholder="Mittelname"
                  onChange={handleChange}
                  value={values.middle_name}
                />
              </Form.Item>
              <Form.Item
                validateStatus={
                  errors.last_name && touched.last_name ? 'error' : ''
                }
                help={
                  errors.last_name && touched.last_name ? errors.last_name : ''
                }
              >
                <Input
                  name="last_name"
                  placeholder="Nachname"
                  onChange={handleChange}
                  value={values.last_name}
                />
              </Form.Item>
              <Form.Item
                validateStatus={
                  errors.username && touched.username ? 'error' : ''
                }
                help={
                  errors.username && touched.username ? errors.username : ''
                }
              >
                <Input
                  name="username"
                  placeholder="Benutzername"
                  onChange={handleChange}
                  value={values.username}
                />
              </Form.Item>
              <Form.Item
                validateStatus={
                  errors.password && touched.password ? 'error' : ''
                }
                help={
                  errors.password && touched.password ? errors.password : ''
                }
              >
                <Input.Password
                  name="password"
                  placeholder="Passwort"
                  onChange={handleChange}
                  value={values.password}
                />
              </Form.Item>
              <Form.Item
                validateStatus={
                  errors.attendee_category && touched.attendee_category
                    ? 'error'
                    : ''
                }
                help={
                  errors.attendee_category && touched.attendee_category
                    ? errors.attendee_category
                    : ''
                }
              >
                <Select
                  name="attendee_category"
                  placeholder="Kategorie wählen"
                  value={values.attendee_category}
                  onChange={(value) =>
                    setFieldValue('attendee_category', value)
                  }
                >
                  <Option value="Gast">Gast</Option>
                  <Option value="Mitglied">Mitglied</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  loading={isLoading}
                  style={styles.registerButton}
                  onClick={() => handleGuestAction(values, 'create')}
                >
                  Gast Registrieren
                </Button>
                <Button
                  type="primary"
                  danger
                  loading={isLoading}
                  style={styles.deleteButton}
                  onClick={() => handleGuestAction(values, 'delete')}
                >
                  Gast Löschen (Vorname, Mittelname, Nachname)
                </Button>
                <Button
                  type="default"
                  style={styles.eventsButton}
                  onClick={() => navigate('/eventsRegistry')}
                >
                  Zu den Events
                </Button>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </div>
    </Content>
  )
}

const styles = {
  content: {
    backgroundColor: '#D51067',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  container: {
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
    maxWidth: '400px',
    width: '100%',
  },
  title: {
    textAlign: 'center',
    marginBottom: '30px',
    color: '#D51067',
  },
  form: {
    width: '100%',
  },
  registerButton: {
    width: '100%',
  },
  deleteButton: {
    width: '100%',
    marginTop: '10px',
  },
  eventsButton: {
    width: '100%',
    marginTop: '10px',
    borderRadius: '15px',
  },
}

export default GuestRegistry
