import API from './client.js'

// API interface for logging a user in
export const login = async (credentials) => {
  try {
    const response = await API.post('auth/login', credentials)

    console.log('Reponse in apis/auth.js ' + JSON.stringify(credentials))

    return response.data
  } catch (err) {
    throw err.response.data
  }
}

export const loginEventAttendee = async (credentials) => {
  try {
    const response = await API.post('auth/loginEventAttendee', credentials);
    return response.data;
  } catch (err) {
    console.log("Error object:", err); // Prüfe hier die genaue Struktur des Fehlers
    if (err.response && err.response.data) {
      // Wenn der Fehler eine Server-Antwort enthält
      console.log(err.response.data.error)
      throw err.response.data; // Dies sollte { error: "Incorrect username or password" } sein
    } else {
      // Wenn kein Server-Response vorhanden ist, handle den Fehler anderweitig
      throw new Error('An unexpected error occurred. Please try again.');
    }
  }
}

// API interface for registering a user
export const register = async (data) => {
  try {
    const response = await API.post('auth/register', data)

    return response.data
  } catch (err) {
    throw err.response.data
  }
}

// API interface for verifying the logged in status of a user
export const isLoggedIn = async () => {
  try {
    const response = await API.get('auth/logged_in')

    return response.data
  } catch (err) {
    throw err.response.data
  }
}
