import React from 'react'
import { Layout } from 'antd'

const { Content } = Layout

const EventSuccess = () => {
  return (
    <Content style={styles.content}>
      <h2 style={styles.title}>
        Du wurdest erfolgreich für das Event registriert. Viel Spaß!
      </h2>
    </Content>
  )
}

const styles = {
  content: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75vh',
  },
  title: {
    color: '#D51067',
    textAlign: 'center', // Text zentrieren
  },
}

export default EventSuccess
